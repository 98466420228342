@import '@4r/ant-theme-config/dist/lib/colors.scss';

$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

.orders-page {
	.orders-top-bar {
		border-bottom: 1px solid $color-grey-10;
		background-color: $color-white;
		display: flex;
		justify-content: space-between;
		padding: 12px 16px;
		width: 100%;

		&__column {
			align-items: center;
			display: flex;
			column-gap: 8px;

			// Calendar styles
			.ant-picker-header {
				border: 1px solid $color-grey-10;
				border-radius: 2px 2px 0 0;
			}
			.ant-picker-body {
				border: 1px solid $color-grey-10;
				border-radius: 0 0 2px 2px;
				border-top: none;
			}
			.ant-picker-cell {
				color: $color-grey-60;

				.ant-picker-cell-inner {
					border-radius: 50%;

					&::before {
						border-radius: 50%;
					}
				}

				&-in-view {
					color: $color-grey-100;
				}
				&-disabled {
					&::before {
						background-color: transparent;
					}

					color: $color-grey-25;
				}
				&-today {
					.ant-picker-cell-inner {
						background-color: transparent;

						&::before {
							border-color: $color-blue-06;
						}
					}
				}
				&-selected {
					.ant-picker-cell-inner {
						background-color: $color-blue-06;

						&::before {
							border-color: transparent;
						}
					}
				}
			}

			.ant-btn[type='button'] {
				display: flex;
			}
		}

		&__date-picker {
			border-width: 0;
			cursor: pointer;
			padding: 0;
			width: 20px;

			.ant-picker-suffix {
				color: $color-grey-80;
				margin: 0;
			}

			&:hover {
				border-right-width: 0 !important;
			}

			&.open {
				border-color: $color-grey-30;
			}

			input {
				display: none;
			}
		}

		&__date {
			display: flex;
			column-gap: 4px;
		}

		&__date-display {
			align-items: center;
			color: $color-grey-100;
			display: flex;
			font-size: 12px;
			font-weight: 600;
			line-height: 19px;
			height: 32px;
			justify-content: center;
			text-transform: uppercase;

			@media screen and (min-width: $xl) {
				width: 150px;
			}
		}

		&__map-switch {
			display: flex;
			align-items: center;
			column-gap: 8px;
		}

		&.mobile {
			border: 1px solid $color-grey-10;
			border-radius: 4px;
		}

		&.hidden-left {
			justify-content: flex-end;
		}
	}

	.button-with-icon {
		color: $color-grey-100;
		padding: 0;

		&.size-middle {
			height: 32px;
			width: 32px;
		}
	}
}
