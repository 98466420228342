@import '@4r/ant-theme-config/dist/lib/colors.scss';

.order-list-table {
	.ant-table-thead {
		.ant-table-cell {
			background-color: #f2f3f4;
			color: $color-grey-100;
			font-size: 12px;
			font-weight: 600;
		}
	}
	.ant-table-cell {
		border-bottom-color: $color-grey-10;
	}
	.order-status-cell {
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		row-gap: 4px;
		&:has(.additional-description) {
			row-gap: 2px;
		}

		.additional-description {
			font-size: 12px;
		}
	}

	.ant-table-placeholder {
		.ant-table-cell:only-child {
			border-bottom: none;
		}
	}
}
